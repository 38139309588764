<!--
 * @Descripttion: 快捷搜索
 * @version: 1.0.0
-->
<template>
<div>
  <el-dropdown trigger="click" @command="handleCommand">
    <el-avatar size="default" :src="circleUrl">
    </el-avatar>
    <template #dropdown>
      <el-dropdown-menu>
        <!-- <el-dropdown-item :icon="User" command="user">个人信息</el-dropdown-item>
        <el-dropdown-item :icon="Edit" command="edit">修改密码</el-dropdown-item> -->
        <el-dropdown-item divided :icon="Close" command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</div>
</template>
<script setup>
import { User,Edit,Close } from '@element-plus/icons-vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useUserStore } from "@/store/user/index.js"
import { useRouter } from "vue-router"
import { useNavTabsStore } from "@/store/navTabs/index"

const navTabsStore = useNavTabsStore()

// const circleUrl = new URL(`../../../assets/vue.svg`, import.meta.url)
const circleUrl = "https://fulei-1308304229.cos.ap-beijing.myqcloud.com/hello.jpg"
const store = useUserStore()
const router = useRouter()

const handleCommand = (key) => {
  const theMap = {
    logout:() => {
      confirmDialog()
    },
    user:() => {
      router.push("/center")
    },
    edit:() => {
      router.push("/center")
    },
  }
  theMap[key] &&  theMap[key]()
}
const confirmDialog = () => {
  ElMessageBox.confirm(
    '您确定要退出登录吗?',
    'Warning',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(() => {
      store.logout()
      navTabsStore.navTabList = []
      router.push("/login")
      ElMessage({
        type: 'success',
        message: '退出登录成功！',
      })
    })
}
</script>
<style scoped lang='scss'>
.el-icon {
  cursor: pointer;
  font-size: 22px;
}
</style>