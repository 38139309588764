/*
 * @Descripttion: 全局主题配置
 * @version: 1.0.0
 */
import { defineStore } from "pinia"
import { reactive,watchEffect } from "vue"
import { handleStorge } from '@/utils/localStorge'
import defaultSettings from "@/settings"
import { changeTheme, changeDark,changeGrey } from "@/utils/changeTheme"
import { settingKey } from "@/assets/static-datas/index"

const lstorge  = handleStorge(localStorage)

export const useSettingsStore = defineStore(settingKey, () => {
  const { theme, isGrey, isDark, breadcrumb, breadcrumbIcon, language, isCollapse,isAccordion,isNavTabs, menuPath,layout } = formatSettings()
  const globalSetting = reactive({
    layout:layout ? layout : "col", // 布局
    theme, // 主题色
    isGrey, // 灰色模式
    isDark, // 暗黑模式
    isNavTabs, // 是否展示菜单栏
    breadcrumb, // 展示面包屑
    breadcrumbIcon, // 展示面包屑的icon
    language, // 语言
    menuPath,// 记录的菜单
    isCollapse, // 是否折叠菜单
    isAccordion // 是否开启菜单手缝琴
  })

  watchEffect(() => {
    lstorge.setItem(settingKey,globalSetting)
    changeTheme(globalSetting.theme)
    changeDark(globalSetting.isDark)
    changeGrey(globalSetting.isGrey)
  })

  const changeSettings = ({ key, value }) => {
    if (globalSetting.hasOwnProperty(key)) {
      globalSetting[key] = value
    }
  }
  return {
    globalSetting,
    changeSettings
  }
})

function formatSettings(){
  const localSettings = lstorge.getItem(settingKey)
  if(localSettings){ return localSettings }
  lstorge.setItem(settingKey,defaultSettings)
  return defaultSettings
}