<!--
 * @Descripttion: 自定义loading
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-23 10:31:43
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-08-23 14:29:27
-->
<template>
  <div class="loading-mask">
    <div class="loading"></div>
    <span>拼命加载中...</span>
  </div>
</template>

<script setup>

</script>
<style scoped lang='scss'>
.loading-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  overflow: hidden;
  background: var(--el-mask-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: var(--el-color-primary);
}

.loading {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background:
    radial-gradient(closest-side circle, var(--el-color-primary) 99%, transparent 100%) center top/25% 25% no-repeat,
    conic-gradient(transparent 10%, var(--el-color-primary) 90%);
  -webkit-mask: radial-gradient(closest-side circle, transparent 50%, red 51% 99%, transparent 100%);
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>