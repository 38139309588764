<template>
  <el-popover :width="350" trigger="click">
    <template #reference>
      <el-icon>
        <Bell />
      </el-icon>
    </template>
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="通知（0）" name="notice"><el-empty description="暂无数据" /></el-tab-pane>
      <el-tab-pane label="待办（0）" name="todo"><el-empty description="暂无数据" /></el-tab-pane>
      <el-tab-pane label="已完成（0）" name="finish"><el-empty description="暂无数据" /></el-tab-pane>
    </el-tabs>
  </el-popover>
</template>

<script setup>
import { Bell } from '@element-plus/icons-vue'
import { ref } from 'vue'
const activeName = ref("notice")
</script>
<style scoped lang='scss'>
.el-icon {
  cursor: pointer;
  font-size: 22px;
  margin-right: 20px;
}
</style>