<template>
  <div class="ebook-navbar">
    <div class="tool-bar-lf flex-align-center">
      <el-icon @click="changeIsCollapse">
        <fold v-if="!isCollapse" />
        <Expand v-else />
      </el-icon>
      <transition name="fade" enter-active-class="animated animate__fadeIn"
        leave-active-class="animated animate__fadeOut">
        <Breadcrumb v-if="breadcrumb"/>
      </transition>
    </div>
    <div class="tool-bar-rf flex-align-center">
      <lockScreen />
      <Bell />
      <Search />
      <Setting />
      <FullScreen />
      <div class="avatar">
        <Avatar/>
      </div>
    </div>
  </div>
  <transition name="fade" enter-active-class="animated animate__bounceIn">
    <div class="ebook-tabs flex-align-center" v-if="isNavTabs" >
      <div class="tab-left">
        <MoreTabs></MoreTabs>
      </div>
      <div class="tab-right flex-align-center">
        <Moreoperation />
      </div>
    </div>
  </transition>
</template>

<script setup>
import { Fold, Expand } from '@element-plus/icons-vue'
import Bell from '@/layout/components/bell/index.vue'
import Setting from '@/layout/components/setting/index.vue'
import lockScreen from '@/layout/components/lockScreen/index.vue'
import FullScreen from '@/layout/components/fullScreen/index.vue'
import Search from '@/layout/components/search/index.vue'
import Moreoperation from '@/layout/components/moreOperation/index.vue'
import MoreTabs from '@/layout/components/moreTabs/index.vue'
import Avatar from '@/layout/components/avatar/index.vue'
import Breadcrumb from '@/layout/components/breadcrumb/index.vue'
import useMenu  from "@/hooks/useMenu/index.js"
const { isCollapse,changeSettings,breadcrumb,isNavTabs } = useMenu()

const changeIsCollapse = () => {
  isCollapse.value = !isCollapse.value
  changeSettings({key:"isCollapse",value:isCollapse.value})
}
</script>
<style scoped lang='scss'>
.el-icon {
  cursor: pointer;
  margin-right: 20px;
  font-size: 22px;
}

.ebook-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  height: 60px;
  border-bottom: 1px solid var(--el-border-color-light);
}

.ebook-tabs {

  height: 40px;
  .tab-left {
    width: calc(100% - 40px);
    padding: 0 15px;
  }

  .tab-right {
    width: 40px;
    height: 100%;
    line-height: 100%;
    border-left: 1px solid var(--el-border-color-light);

    &:hover {
      background-color: var(--el-border-color-light);
    }

    .el-dropdown {
      margin-left: 8px;
    }
  }
}

.avatar {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
</style>