/*
 * @Descripttion: 菜单导航栏记录数据
 * @version: 1.0.0
 */
import { useRouter } from "vue-router"
import { defineStore } from "pinia"
import { ref } from "vue"
import { navTabsKey } from "@/assets/static-datas/index"
import {useSettingsStore} from "../settings/index"

export const useNavTabsStore = defineStore(navTabsKey, () => {
  const router = useRouter()
  const settingStore = useSettingsStore()
  const navTabList = ref([])
  const addTabsItem = (item) => {
    const hasItem = navTabList.value.find(v => v.path === item.path)
    if(!hasItem){
      navTabList.value.push(item)
    }
  }
  const removeNavTabsItem = (path,menuPath) =>{
    const index = navTabList.value.findIndex(item => item.path === path)
    navTabList.value.splice(index,1)
    const preItem = navTabList.value[index - 1]
    router.push(preItem.path)
    settingStore.globalSetting.menuPath = preItem.path
  }
  const removeOtherNavTabs = () =>{
    const index = navTabList.value.findIndex(item => item.path === settingStore.globalSetting.menuPath)
    navTabList.value.splice(index + 1,navTabList.value.length - 1)
    navTabList.value.splice(1,navTabList.value.length - 2)
  }
  const closeAllNavTabs = () => {
    navTabList.value.splice(1,navTabList.value.length - 1)
    router.push("/index")
    settingStore.globalSetting.menuPath = "/index"
  }
  return {
    navTabList,
    addTabsItem,
    removeNavTabsItem,
    removeOtherNavTabs,
    closeAllNavTabs
  }
},
// {
//   isPersist: true
// }
)

// function formatSettings(){
//   const sessionSettings = sstorge.getItem(navTabsKey)
//   if(sessionSettings){ return sessionSettings }
//   sstorge.setItem(navTabsKey,[])
//   return []
// }