<!--
 * @Descripttion: 快捷搜索
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-17 10:44:35
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-11-01 17:20:15
-->
<template>
  <el-icon>
    <Search @click="dialogVisible = true" />
  </el-icon>
  <el-dialog v-if="dialogVisible" v-model="dialogVisible" title="菜单快捷搜索" width="30%" draggable>
    <div>
      <el-select v-model="inputValue" placeholder="请输入菜单名称～" style="width:100%" filterable @change="getInput">
        <el-option v-for="item in options" :key="item.path" :label="item.meta.title" :value="item.path">
        </el-option>
      </el-select>
    </div>
  </el-dialog>
</template>

<script setup>
import { routes } from '@/router/index'
import { Search } from '@element-plus/icons-vue'
import { markRaw, ref } from "vue"
import { flatTreeArray } from "@/utils/common.js"
import useMenu from "@/hooks/useMenu"
const { selectPath } = useMenu()
let menuList = markRaw(routes[0].children) //获取菜单列表
const options = flatTreeArray(menuList)
let dialogVisible = ref(false)
let inputValue = ref("")
function getInput(value) {
  selectPath(value)
  dialogVisible.value = false
  inputValue.value = ref("")
}
</script>
<style scoped lang='scss'>
.el-icon {
  cursor: pointer;
  font-size: 22px;
  margin-right: 20px;
}
</style>