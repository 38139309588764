/*
 * @Descripttion: 路由前置守卫
 * @version: 1.0.0
 */
import nprogress from '@/utils/nprogress'
import { useUserStore } from "@/store/user/index"
import { useNavTabsStore } from "@/store/navTabs/index"
import { useTheme } from "@/hooks/useTheme/index.js"
import { ElMessage } from 'element-plus'

const whiteList = ["/login"]
export default function beforeEach(to, from, next) {
  const userStore = useUserStore()
  const navTabsStore = useNavTabsStore()
  const { menuPath } = useTheme()
  const token = sessionStorage.getItem("token")
  nprogress.start()
  if (token) {
    if (to.path === "/login") {
      next("/")
    } else {
      let currentRole = sessionStorage.getItem('role');
      if(to.meta.permiss.includes(Number(currentRole))){
        if(!to.meta.hidden) {
          navTabsStore.addTabsItem({name:to.name,path:to.path,title:to.meta.title})
          menuPath.value = to.path
        }
        next()
      }else{
        ElMessage.warning("您没有权限访问！")
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
  nprogress.done()
}