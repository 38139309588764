<!--
 * @Descripttion: layout
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-11 15:40:01
 * @LastEditors: fulei🐰
 * @LastEditTime: 2024-04-10 11:31:50
-->
<template>
  <div class='laayout'>
    <transition name="fade">
      <layoutCol v-if="layout === 'col'" />
    </transition>
    <transition name="fade">
      <layoutRow v-if="layout === 'row'"></layoutRow>
    </transition>
  </div>
</template>

<script setup>
import layoutCol from "./layout-col/index.vue"
import layoutRow from "./layout-row/index.vue"
import { useTheme } from '@/hooks/useTheme'

const { layout, changeSettings } = useTheme()
const change = () => {
  changeSettings({ key: "layout", value: "col" })
}
</script>
<style scoped lang='scss'></style>