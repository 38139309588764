<!--
 * @Descripttion: layout纵向布局
 * @version: 1.0.0
-->
<template>
  <div class='ebook'>
    <aside class="ebook-aside" :style="{ width: layoutColStyles?.asideWidth }">
      <div class="logo">
        <span v-show="!isCollapse" class="logo-title-text">运营平台</span>
      </div>
      <div class="menu">
        <el-menu :collapse="isCollapse" @select="selectPath" class="el-menu-vertical-demo" :default-active="menuPath"
          :unique-opened="isAccordion" :collapse-transition="false">
          <SideBar v-for="item in menuList" :key="item.id" :menuItem="item" />
        </el-menu>
      </div>
    </aside>
    <section class="ebook-section" :style="{ width: layoutColStyles?.sectionWidth }">
      <header class="ebook-section-header" :style="{ height: layoutColStyles?.headerHeight }">
        <NavBar />
      </header>
      <main class="ebook-section-main" :style="{ height: layoutColStyles?.mainHeight }">
        <div class="app-main" enter-active-class="animated animate__fadeIn">
          <router-view />
        </div>
      </main>
      <Footer />
    </section>
  </div>
</template>

<script setup>
import SideBar from "./components/SideBar/index.vue"
import NavBar from "./components/navBar/index.vue"
import Footer from "../components/footer/index.vue"
import useMenu from "@/hooks/useMenu"
const { selectPath, layoutColStyles, menuList, isCollapse, isAccordion, menuPath } = useMenu()

</script>
<style scoped lang='scss'>
.ebook {
  background-color: var(--el-bg-color-page);
  display: flex;
  overflow: hidden;

  &-aside {
    animation: aniLeftRight 400ms ease-in-out;
    // width: 210px;
    height: 100vh;
    transition: width 0.2s;
    -webkit-transition: width 0.2s;
    -moz-transition: width 0.2s;
    -webkit-transition: width 0.2s;
    -o-transition: width 0.2s;

    .logo {
      background-color: #fff;
      height: 55px;
      text-align: center;
      line-height: 55px;
      border-right: 1px solid var(--el-border-color-light);
      font-size: 21.5px;
      font-weight: 700;
      color: var(--el-aside-logo-text-color);
      white-space: nowrap;

      img {
        width: 28px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }

    .menu {
      height: calc(100vh - 55px);
      // overflow-y: scroll;
      // background-color:#141414 !important;
    }
  }

  &-section {

    // width: calc(100vw - 210px);
    &-header {
      // height: 100px;
      animation: aniTopBottom 400ms ease-in-out;
      background-color: #fff;
    }

    &-main {
      height: calc(100vh - 130px);
      padding: 10px 12px;
      background-color: var(--el-bg-color-page);

      .app-main {
        height: 100%;
        overflow-y: scroll;
      }
    }
  }
}

</style>