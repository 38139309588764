/*
 * @Description: 接口请求统一配置
 * @Version: 0.1
 */
import axios from "axios"
import { ElMessage } from 'element-plus'
// 环境变量
const API_BASEURL = (import.meta.env.VITE_API_BASE_URL)  //api接口基础路径
const CLIENT_TIMEOUT = 40000 //链接时间
const service = axios.create({
  baseURL: API_BASEURL,
  timeout: CLIENT_TIMEOUT,
  headers: {
    // "Content-Type": "application/json;charset=utf-8"
		"Content-Type": "multipart/form-data"
  }
})

//请求拦截
service.interceptors.request.use((config) => {
	config.headers = config.headers || {}  
	if (sessionStorage.getItem("token")) {
		config.headers.authorization = sessionStorage.getItem("token") || ""
	}
	return config
}, error => {
	//处理错误请求
	return Promise.reject(error)
})
//响应拦截
service.interceptors.response.use((res) => {
	const code = res.data.code
	if (code === 0 ) {
		//请求成功
		return Promise.resolve(res.data)
	} else {
		ElMessage.error(res?.data?.message || "接口请求失败，请稍后重试！")
		return Promise.reject(res)
	}
}, (err) => {
  ElMessage.error(err?.message || "系统繁忙请稍后重试！")
	return Promise.reject(err)
})


export default service