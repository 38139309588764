/*
 * @Descripttion: 用户信息
 * @version: 1.0.0
 */
import { defineStore } from "pinia"
import { userKey } from "@/assets/static-datas/index"
import { loginApi, getDefaultFilePrefix } from "@/api/modules/common-api.js"

export const useUserStore = defineStore(userKey, {
  state: () => ({
    editType: false,
    mainEditType: false,
  }),
  actions: {
    setEditType(type) {
      this.editType = type
    },
    setMainEditType(type) {
      this.mainEditType = type
    },
    async login(data) {
      try {
        const result = await loginApi(data)
        const { userName, token, role } = result?.data || {}
        sessionStorage.setItem('token', token)
        sessionStorage.setItem('role', role)
        sessionStorage.setItem('userName', userName)
        const urlPrefix = await getDefaultFilePrefix()
        sessionStorage.setItem('urlPrefix', urlPrefix.data)
        return result?.data || {}
      } catch (error) {
        return {}
      }
    },
    logout() {
      window.sessionStorage.clear()
      this.token = ""
    },
  }
})



