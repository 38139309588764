import { useSettingsStore } from "@/store/settings/index.js"
import { storeToRefs } from "pinia"
import {toRefs} from "vue"
export  function useTheme() {
  const settingStore = useSettingsStore()
  const { globalSetting } = storeToRefs(settingStore)
  const { changeSettings } = settingStore
  return {
    ...toRefs(globalSetting.value),
    changeSettings
  }
}