<!--
 * @Descripttion: 切换语言
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-17 10:43:35
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-08-22 14:45:50
-->
<template>
  <el-icon>
    <FullScreen @click="onToggle"/>
  </el-icon>
</template>

<script setup>
import { FullScreen } from '@element-plus/icons-vue'
import { ref, onMounted, onUnmounted } from "vue";
import screenfull from "screenfull";

 // 是否全屏
 const isFullscreen = ref(false);

 // 监听变化
 const change = () => {
   isFullscreen.value = screenfull.isFullscreen;
 };

 // 切换事件
 const onToggle = () => {
   screenfull.toggle();
 };

 // 设置侦听器
 onMounted(() => {
   screenfull.on("change", change);
 });

 // 删除侦听器
 onUnmounted(() => {
   screenfull.off("change", change);
 });
</script>
<style scoped lang='scss'></style>