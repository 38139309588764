<!--
 * @Descripttion: 面包屑
 * @version: 1.0.0
-->
<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item v-for="(item, index) in routers" :key="index" @click.native="toPath(item)">
      <span class="breadcurmb-text">{{ item?.meta?.title }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router"
const router = useRouter()
const route = useRoute()
const routers = computed(() => {
  return route.matched.filter(item => item.meta.title)
})
const toPath = (item) => {
  router.push(item.path)
}
</script>
<style scoped lang='scss'>
:deep(.el-breadcrumb__inner) {
  display: flex;
  align-items: center;
}
.breadcurmb-text{
  color: var(--el-header-text-color);
}
.menu-icon {
  width: 20px;
  margin-right: 4px;
}
</style>