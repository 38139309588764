import { routes } from '@/router/index'
import { useRouter } from "vue-router"
import { useTheme } from "../useTheme/index"
import { computed } from "vue"
import { useNavTabsStore } from "@/store/navTabs/index"
import { storeToRefs } from 'pinia'
import screenfull from 'screenfull'

export default function useMenu() {
  const navTabsStore = useNavTabsStore()
  const router = useRouter()
  const { navTabList } = storeToRefs(navTabsStore)
  let currentRole = sessionStorage.getItem('role') ;
  let menuList = routes[0].children //获取菜单列表
  
  let list = filterRouter(menuList)
  menuList = filterHidden(list)

  const { isCollapse, changeSettings,isAccordion,menuPath,breadcrumb,isNavTabs } = useTheme() //获取菜单折叠状态

  const layoutColStyles = computed(() => {
    return {
      asideWidth: isCollapse.value ? "65px" : "210px",
      sectionWidth: `calc(100vw - ${isCollapse.value ? "65px" : "210px"})`,
      headerHeight: isNavTabs.value ? "100px" : "60px",
      mainHeight: `calc(100vh - ${isNavTabs.value ? "130px" : "90px"})`,
      mainHeightRow: `calc(100vh - ${isNavTabs.value ? "130px" : "90px"})`
    }
  })

  function filterHidden(arr) {
    return arr.filter(route => {
      if (route.children) {
        route.children = route.children.filter(child => !child.meta.hidden);
      }
      return true;
    });
  }

  // 对路由进行权限过滤
  function filterRouter(list) {
    let arr = list.filter(item => item.meta.permiss.includes(Number(currentRole)))
    return arr.map(item => {
      if (item.children && item.children.length > 0) {
        return {
          ...item,
          children: filterRouter(item.children)
        };
      }
      return { ...item, children: [] };
    })
  }

  const selectPath = (path) => {
    router.push({ path })
    menuPath.value = path
  }

  const routeReplaceByTabsChange = (path) => { 
    router.push({ path })
  }
  const removeNavTabsItem = (path) => { 
    navTabsStore.removeNavTabsItem(path)
  }

  const handleCommand = (type) => {
    const theMap = {
      refresh: () => {
        window.location.reload()
       },
      fullScreen: () => { 
        let textDom = document.getElementsByClassName('app-main')[0]
        screenfull.toggle(textDom)
       },
      closeCurrMenu: () => { 
        removeNavTabsItem(menuPath.value)
       },
      closeOtherMenu: () => { 
        navTabsStore.removeOtherNavTabs()
       },
      closeAll: () => {
        navTabsStore.closeAllNavTabs()
       }
    }
    if(theMap[type]){
      theMap[type]()
     }
  }
  const _settingsState = []
  const navTabs = []
  const currentPath = "/"
  return {
    isCollapse, //菜单是否折叠
    changeSettings, // 切换配置
    isAccordion, // 是否开启菜单手风琴
    menuPath, // 记录最后选择的菜单
    navTabList, // navbar记录的数组
    routeReplaceByTabsChange, // 点击navbar的某一项切换地址
    removeNavTabsItem, // 移除navbar的某一项
    breadcrumb,
    isNavTabs,
    menuList,
    layoutColStyles,
    selectPath,
    _settingsState,
    handleCommand,
    navTabs,
    currentPath,

  }
}