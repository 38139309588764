/*
 * @Descripttion: 自定义loading指令
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-23 10:44:19
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-08-23 14:30:19
 */
import { createApp } from 'vue';
import Loading from "@/components/loading/index.vue"
const loading= {
  mounted(el,binding){
    const app = createApp(Loading);
    const instance = app.mount(document.createElement('div'));
    el.instance = instance;
    el.style.position = 'relative';
    if (binding.value) {
      appendEl(el);
    }
  },
  updated(el,binding) {
    if (binding.value !== binding.oldValue) {
      binding.value ? appendEl(el) : removeEl(el); 
    }
  },
};

const appendEl = (el) =>{
  el.appendChild(el.instance.$el);
};

const removeEl = (el) =>{
  el.removeChild(el.instance.$el);
};

export default loading;

