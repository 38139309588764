<template>
  <el-tabs v-model="menuPath" @tab-remove="removeNavTabsItem" @tab-change="routeReplaceByTabsChange">
    <el-tab-pane v-for="(item, index) in navTabList" :closable="(menuPath !== '/index') && (menuPath === item.path)"
      :key="index" :label="item.title" :name="item.path">
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import useMenus from "@/hooks/useMenu"
const { navTabList, menuPath, routeReplaceByTabsChange, removeNavTabsItem } = useMenus()
// let currentRole = sessionStorage.getItem('role')
// if (String(currentRole) !== "0") {
//   navTabList.value = []
// }
</script>
<style scoped lang='scss'>
.el-tabs.el-tabs--top {
  height: 40px;
}

:deep(.el-tabs__item) {
  color: #afafaf;
  min-width: 100px;
}

:deep(.el-tabs__item.is-active) {
  color: var(--el-color-primary);

}
</style>