/*
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-29 09:35:54
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-09-06 11:13:21
 */
// src/plugin/storge.js
export function useStorage(context) {
  // context.pinia 使用 `createPinia()` 创建的 pinia
  // context.app 使用 `createApp()` 创建的当前应用程序（仅限 Vue 3）
  // context.store 插件正在扩充的 store
  // context.options 定义存储的选项对象传递给`defineStore()`
  // ...
  const { store, options } = context
  if (options?.isPersist) {
    let storage = localStorage
    const storageList = [localStorage, sessionStorage, undefined]
    // 判断存储方式是否存在
    if (storageList.includes(options.storage)) {
      storage = options.storage || localStorage
    } else {
      throw new Error(options.storage + '不存在')
    }
    // 若有本地存储，使用存储的值；否则使用默认值，并存储
    if (!!storage.getItem(store.$id)) {
      store.$state = JSON.parse(storage.getItem(store.$id))
    } else {
      storage.setItem(store.$id, JSON.stringify(store.$state))
    }
    // 监听数据变化，存储到自定义的 storage 中
    store.$subscribe((mutation, state) => {
      storage.setItem(store.$id, JSON.stringify(store.$state))
    })
  }
}