/*
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-09 17:49:23
 * @LastEditors: your name
 * @LastEditTime: 2024-05-09 13:22:21
 */
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import  piniaPluginPersistedstate  from 'pinia-plugin-persistedstate'
import loading from '@/directives/loading/index';
import 'element-plus/dist/index.css'
import "@/styles/index.scss"
import 'animate.css';
import router from "@/router/index"
import App from './App.vue'
import { useStorage } from '@/plugin/storge' // 引入插件
import { createPinia } from "pinia";
import gloablComponent from './components/index.js';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

const pinia = createPinia();
const app = createApp(App)
pinia.use(piniaPluginPersistedstate)
pinia.use(useStorage)
app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
};

app.use(router)
app.use(pinia)
app.use(gloablComponent)
app.directive('load',loading);
app.mount('#app')
