<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-17 16:11:35
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-08-17 19:19:32
-->
<template>
  <footer class="ebook-section-footer">
    <a href="#" id="Technology"> 2024 © boss-web By Hzgbt Technology. </a>
  </footer>
</template>
<script setup>

</script>
<style scoped lang='scss'>
footer {
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: var(--el-bg-color);
  border-top: 1px solid var(--el-border-color-light);
  border-left: 1px solid var(--el-border-color-light);
  animation: aniBottomTop 400ms ease-in-out;
  a {
    color: var(--el-text-color-secondary);
    font-size: 14px;
  }
}
</style>