<!--
 * @Descripttion: 
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-09-07 14:55:11
 * @LastEditors: fulei🐰
 * @LastEditTime: 2024-04-10 11:42:56
-->
<template>
  <div class='ebook'>
    <header class="ebook-header">
      <div class="logo">
        <span class="logo-title-text">运营平台</span>
      </div>
      <div class="menu">
        <el-menu :collapse="isCollapse" mode="horizontal" @select="selectPath" class="el-menu-vertical-demo"
          :default-active="menuPath" :unique-opened="isAccordion" :collapse-transition="false">
          <SideBar v-for="item in menuList" :key="item.id" :menuItem="item" />
        </el-menu>
      </div>
      <div class="bar flex-align-center">
        <NavBar />
      </div>
    </header>
    <transition name="fade" enter-active-class="animated animate__fadeIn"
      leave-active-class="animated animate__zoomOutUp">
      <div class="ebook-navbar flex-align-center" v-if="isNavTabs">
        <div class="tab-left">
          <NavTabs />
        </div>
        <div class="tab-right flex-align-center">
          <MoreOperation />
        </div>
      </div>
    </transition>
    <main class="ebook-main" :style="{ height: layoutColStyles?.mainHeightRow }">
      <div class="app-main" enter-active-class="animated animate__fadeIn">
          <router-view />
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import Footer from "../components/footer/index.vue"
import NavTabs from "../components/moreTabs/index.vue"
import MoreOperation from "../components/moreOperation/index.vue"
import NavBar from "./components/navBar/index.vue"
import SideBar from "./components/sideBar/index.vue"
import useMenu from "@/hooks/useMenu"
const { selectPath, layoutColStyles, menuList, isCollapse, isAccordion, menuPath, isNavTabs, mainHeightRow } = useMenu()
</script>
<style scoped lang='scss'>
.ebook {
  background-color: var(--el-bg-color-page);
  overflow: hidden;

  &-header {
    display: flex;
    align-items: center;
    height: 60px;
    animation: aniTopBottom 400ms ease-in-out;
    background-color: #fff;

    .logo {
      width: 210px;
      background-color: #fff;
      height: 55px;
      text-align: center;
      line-height: 55px;
      font-size: 21.5px;
      font-weight: 700;
      color: var(--el-aside-logo-text-color);
      white-space: nowrap;

      img {
        width: 28px;
        margin-right: 6px;
        vertical-align: middle;
      }
    }

    .menu {
      width: calc(100vw - 540px);
    }

    .bar {
      display: flex;
      justify-content: space-around;
      width: 300px;
    }
  }

  &-navbar {
    animation: aniTopBottom 400ms ease-in-out;

    display: flex;
    height: 40px;
    background-color: #fff;
    border-top: 1px solid #eee;

    .tab-left {
      width: calc(100% - 40px);
      padding: 0 15px;
    }

    .tab-right {
      width: 40px;
      height: 100%;
      line-height: 100%;
      border-left: 1px solid var(--el-border-color-light);

      &:hover {
        background-color: var(--el-border-color-light);
      }

      .el-dropdown {
        margin-left: 8px;
      }
    }
  }

  &-main {
    // height: calc(100vh - 130px);
    padding: 10px 12px;
    background-color: var(--el-bg-color-page);

    .app-main {
      height: 100%;
      overflow-y: scroll;
    }
  }
}
.el-card{
   height: 100%;
   border-radius: 5px;
   animation: anitRightLeft 400ms ease-in-out;
 }
</style>