<!--
 * @Descripttion: 快捷搜索
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-17 10:44:35
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-08-23 14:50:09
-->
<template>
  <el-dropdown trigger="click" @command="handleCommand">
    <el-icon>
      <arrow-down />
    </el-icon>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :icon="Refresh" command="refresh">刷新</el-dropdown-item>
        <el-dropdown-item :icon="FullScreen" command="fullScreen">最大化</el-dropdown-item>
        <el-dropdown-item divided :icon="Close" command="closeCurrMenu">关闭当前</el-dropdown-item>
        <el-dropdown-item :icon="TurnOff" command="closeOtherMenu">关闭其他</el-dropdown-item>
        <el-dropdown-item :icon="Finished" command="closeAll">关闭所有</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script setup>
import { ArrowDown,Refresh,FullScreen,Close,Finished,TurnOff } from '@element-plus/icons-vue'
import useMenus from "@/hooks/useMenu"
const { handleCommand } = useMenus()

</script>
<style scoped lang='scss'>
.el-icon {
  cursor: pointer;
  font-size: 22px;
}
</style>