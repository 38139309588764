/**
 * 遍历深林(广度优先)
 * @param {*} trees
 * @param {*} callback
 */
export function travelTrees(trees, callback) {
  // 浅拷贝
  const queue = trees.slice(0)
  for (let i = 0; i < queue.length; i++) {
    const node = queue[i]
    callback(node)
    if (node.dictList && node.dictList.length) {
      queue.push(...node.dictList)
    }
  }
}

export function flatTreeArray(data) {
  return data.reduce((pre, cur) => {
    const { children = [], ...i } = cur   // 注意 ...i 只能写在解构赋值的末尾，否则报错
    return pre.concat([{ ...i }], flatTreeArray(children))  //利用递归回调,数组合并,注意此处 {...i}是解构
  }, [])
}

// 获取用户信息
export const getUser = () => {
  if (
    sessionStorage.getItem("user") === null ||
    sessionStorage.getItem("user") === ""
  ) {
    return null
  } else {
    return JSON.parse(sessionStorage.getItem("user"))
  }
}

