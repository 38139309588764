/*
 * @Descripttion:设置localStorge
 * @version: 1.0.0
 */
export function handleStorge(storgeType){
  return {
    getItem (key) {
      try {
        return JSON.parse(storgeType.getItem(key))
      } catch (err) {
        return []
      }
    },
    setItem (key, val) {
      try {
        storgeType.setItem(key, JSON.stringify(val))
      } catch (err) {
        return err
      }
    },
    clear () {
      storgeType.clear()
    },
    keys () {
      return storgeType.keys()
    },
    removeItem (key) {
      storgeType.removeItem(key)
    }
  }
}
