export default {
  theme: "#409eff", // 主题色
  layout:"col", // 布局方向
  isGrey: false, // 灰色模式
  isDark: false, // 暗黑模式
  isNavTabs:true,//页签
  breadcrumb: true, // 展示面包屑
  breadcrumbIcon: true, // 展示面包屑的icon
  language: "zh", // 语言
  navTabs:[], //浏览过的菜单
  menuPath: "/index",// 记录的菜单
  isAccordion:false, //菜单手风琴
  isCollapse: false // 是否折叠菜单
}