import request from "../axios"
import axios from "axios"

// 登录接口
export function loginApi(data) {
  return request({
    url: `/op/login`,
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 运营主体管理 列表查询
export function getOperatingList(data) {
  return request({
    url: `/op/operating-entity/list`,
    method: "post",
    data
  })
}
// 添加/修改运营主体
export function saveOperate(data) {
  return request({
    url: `/op/operating-entity/saveOrUpdate`,
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 获取某个运营主体详情
export function getOperatingDetail(data) {
  return request({
    url: `/op/operating-entity/detail`,
    method: "post",
    data
  })
}

export function resetOperatePassword(data) {
  return request({
    url: `/op/operating-entity/passwordreset`,
    method: 'post',
    data
  })
}

// 获取参数配置(多个页面使用)
export function getConfig(data) {
  return request({
    url: `/op/operating-entity/getOperEntityConf`,
    method: "post",
    data
  })
}
// 参数配置--保存(多个页面使用)
export function saveConfig(data) {
  return request({
    url: '/op/operating-entity/saveOperEntityConf',
    method: "post",
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 获取 账号管理-列表
export function getAccountList(data) {
  return request({
    url: `/op/account/list`,
    method: "post",
    data
  })
}
// 获取 账号管理某个账号详情
export function getAccountDetail(data) {
  return request({
    url: `/op/account/detail`,
    method: 'post',
    data
  })
}
// 添加/修改运营帐号
export function saveAccount(data) {
  return request({
    url: `/op/account/saveOrUpdate`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
export function resetAccountPassword(data) {
  return request({
    url: `/op/account/passwordrest`,
    method: 'post',
    data
  })
}

// 获取软件商品列表
export function getSoftItemList(data) {
  return request({
    url: `/op/softitem/list`,
    method: 'post',
    data
  })
}
// 添加/修改软件商品
export function saveSoftItem(data) {
  return request({
    url: `/op/softitem/saveOrUpdate`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 获取默认的文件访问前缀
export function getDefaultFilePrefix(data) {
  return request({
    url: `/common/oss/getDefaultFilePrefix`,
    method: 'post',
    data
  })
}
// 获取运营上传文件url
export function uploadOssFile(data) {
  return request({
    url: `/op/common/oss/getUploadUrl`,
    method: 'post',
    data
  })
}
// 文件推送到oss
export function putOSS(data, file) {
  const formData = new FormData();
  formData.append('OSSAccessKeyId', data.accessid)
  formData.append('policy', data.policy)
  formData.append('signature', data.signature)
  formData.append('dir', data.dir)
  formData.append('host', data.host)
  formData.append('key', data.key)
  formData.append('file', file, file.name);
  
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: data.host,
      data: formData,
      headers: {
        authorization: sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      }
    }).then((response) => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}


// 软件oem分页查询
export function getOEMList(data) {
  return request({
    url: `/op/softoem/list`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 添加/修改软件oem
export function saveOEM(data) {
  return request({
    url: `/op/softoem/saveOrUpdate`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 获取微信小程序的授权二维图片
export function getWxMiniAuthQrcode(data) {
  return request({
    url: `/op/softoem/getWxMiniAuthQrcode`,
    method: 'post',
    data
  })
}
// 获取微信小程序的体验码图片
export function getPreviewCode(data) {
  return request({
    url: `/op/softoem/getPreviewCode`,
    method: 'post',
    data
  })
}
// 设置某个微信小程序的域名
export function setDomain(data) {
  return request({
    url: `/op/softoem/setDomain`,
    method: 'post',
    data
  })
}
// 提交微信小程序代码
export function commitCode(data) {
  return request({
    url: `/op/softoem/commitCode`,
    method: 'post',
    data
  })
}

// 获取余额提现设置
export function getWithdrawConfig(data) {
  return request({
    url: `/op/balance/withdraw/getWithdrawConfig`,
    method: 'post',
    data
  })
}
// 余额提现设置
export function setWithdrawConfig(data) {
  return request({
    url: `/op/balance/withdraw/setWithdrawConfig`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 获取软件商品的参数配置
export function getGoodConf(data) {
  return request({
    url: `/op/softitem/getConf`,
    method: 'post',
    data
  })
}
// 保存软件商品的参数配置
export function saveGoodConf(data) {
  return request({
    url: `/op/softitem/saveConf`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 获取软件oem的参数配置
export function getOEMConf(data) {
  return request({
    url: `/op/softoem/getConf`,
    method: 'post',
    data
  })
}
// 保存软件OEM的参数配置
export function saveOEMConf(data) {
  return request({
    url: `/op/softoem/saveConf`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 公开资料查询
export function getPublicList(data) {
  return request({
    url: `/op/publicRes/query`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 获取某个公开资料
export function getPublicDetail(data) {
  return request({
    url: `/op/publicRes/detail`,
    method: 'post',
    data
  })
}

// 添加/修改公开资料
export function savePublicData(data) {
  return request({
    url: `/op/publicRes/saveOrUpdate`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 提现记录查询
export function withdrawRecord(data) {
  return request({
    url: `/op/balance/withdraw/listWithDrawInCondition`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 查询某个提现申请详情
export function getWithdrawDetailByFlowId(data) {
  return request({
    url: `/op/balance/withdraw/getWithdrawDetailByFlowId`,
    method: 'post',
    data
  })
}
// 提现审核
export function checkWithdrawApply(data) {
  return request({
    url: `/op/balance/withdraw/checkWithdrawApply`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 打款确认
export function confirmWithdrawPay(data) {
  return request({
    url: `/op/balance/withdraw/confirmWithdrawPay`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 查询用户余额列表
export function getUserBalanceList(data) {
  return request({
    url: `/op/balance/getUserBalanceList`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 查询某个用户余额变动记录
export function getUserBalanceLog(data) {
  return request({
    url: `/op/balance/getUserBalanceLog`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 查询 订单列表
export function getOrderList(data) {
  return request({
    url: `/op/order/appOrderQuery`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 申请退款
export function appRefund(data) {
  return request({
    url: `/op/order/appRefund`,
    method: 'post',
    data
  })
}
// 查询退款状态
export function refundStatus(data) {
  return request({
    url: `/op/order/refundStatus`,
    method: 'post',
    data
  })
}

// 获取用户列表
export function getUserList(data) {
  return request({
    url: `/op/user/appUserQuery`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 获取用户的参数配置
export function getUserConfig(data) {
  return request({
    url: `/op/user/getConf`,
    method: 'post',
    data
  })
}
// 保存用户的参数配置
export function saveUserConfig(data) {
  return request({
    url: `/op/user/saveConf`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}

// 优惠码查询
export function getCouponCode(data) {
  return request({
    url: `/op/promCode/query`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 获取某个优惠码信息
export function getCouponCodeDetail(data) {
  return request({
    url: `/op/promCode/detail`,
    method: 'post',
    data
  })
}
// 添加/修改优惠码
export function saveCouponCode(data) {
  return request({
    url: `/op/promCode/saveOrUpdate`,
    method: 'post',
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    data
  })
}
// 删除某个优惠码
export function deleteCouponCode(data) {
  return request({
    url: `/op/promCode/delete`,
    method: 'post',
    data
  })
}

// 获取某个软件oem的详情
export function getSoftoemDetail(data) {
  return request({
    url: `/op/softoem/detail`,
    method: 'post',
    data
  })
}


