/*
 * @Descripttion:NProgress进度条
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-03 15:09:42
 * @LastEditors: fulei🐰
 * @LastEditTime: 2023-08-03 17:35:32
 */
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

// 全局进度条的配置
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 1000, // 递增进度条的速度
  showSpinner: false, // 是否显示ico
  trickleSpeed: 500, // 自动递增间隔
  minimum: 0.3, // 更改启动时使用的最小百分比
  parent: 'body' // 指定进度条的父容器
})
export default {
  start: () => {
    NProgress.start()
  },
  done: () => {
    NProgress.done()
  }
}
// // 开启进度条
// export const NProgressStart = () => {
//   NProgress.start()
// }
// // 关闭进度条
// export const NProgressDone = () => {
//   NProgress.done()
// }
