import { ElMessage } from 'element-plus'
import Color from "color"
const regHEXColor = /^#?[0-9A-Fa-f]{6}$/ //hex正则校验
const DOM = document.documentElement


export const changeDark = (isDark) => {
  if (isDark) DOM.setAttribute('class', 'dark')
  else DOM.setAttribute('class', '')
}


export const changeTheme = (color) => {
  if (!regHEXColor.test(color)) return ElMessage.warning('切换主题色失败，传入了错误的hex颜色值！')
  changeHTMLProperty('--el-color-primary', color)
  changeHTMLProperty('--el-color-primary-dark-2', color)
  for (let i = 1; i <= 9; i++) {
    changeHTMLProperty(`--el-color-primary-light-${i}`, `${getColors(color, i / 10)}`)
  }
}
const getColors = (color, level) => {
 const hex =  Color(color)
  .mix(Color("white"), level)
  .hex() 
  return hex
}
const changeHTMLProperty = (property, color) => {
  DOM.style.setProperty(property, color)
}

export const changeGrey = (flag) =>{
  const { style } = document.documentElement
  style.filter = flag ? 'grayscale(100%)' : ''
}