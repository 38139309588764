import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout/index.vue'
import { User, Goods, Coin, Wallet, CreditCard, Suitcase, Box, Folder, Document, Camera, Operation, Compass  } from '@element-plus/icons-vue'
import beforeEach from "./beforeEach"

export const routes = [
  {
    path: '/',
    component: Layout,
    // redirect: '/index',
    children: [
      {
        path: '/system',
        name: "system",
        meta: {
          id: '1',
          icon: Box,
          permiss: [0, 1, 2],
          subRole: ['-1'],
          title: '系统管理'
        },
        // redirect: "/operation-main-body",
        children: [
          {
            path: '/operation-main-body',
            name: "operation-main-body",
            meta: {
              id: '1-1',
              icon: Operation,
              permiss: [0],
              subRole: ['-1'],
              title: '运营主体管理'
            },
            component: () => import('@/views/system-module/operation-main-body/index.vue')
          },
          {
            path: '/account-manage',
            name: "account-manage",
            meta: {
              id: '1-2',
              icon: User,
              permiss: [1],
              subRole: ['-1'],
              title: '账号管理'
            },
            component: () => import('@/views/system-module/account-manage/index.vue')
          },
          {
            path: '/commodity-manage',
            name: "commodity-manage",
            meta: {
              id: '1-3',
              icon: Goods,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '软件商品管理'
            },
            component: () => import('@/views/system-module/commodity-manage/index.vue')
          },
          {
            path: '/oem-manage',
            name: "oem-manage",
            meta: {
              id: '1-4',
              icon: Compass,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '软件OEM管理'
            },
            component: () => import('@/views/system-module/oem-manage/index.vue')
          },
        ]
      },
      {
        path: '/video-studio',
        name: "video-studio",
        meta: {
          id: '2',
          icon: Camera,
          name: 'video-studio',
          permiss: [1, 2],
          subRole: ['-1'],
          title: '影像工坊'
        },
        // redirect: "/deal",
        children: [
          {
            path: '/deal',
            name: "deal",
            meta: {
              id: '2-1',
              icon: Goods,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '订单管理'
            },
            component: () => import('@/views/video-studio/deal/index.vue')
          },
          {
            path: '/user',
            name: "user",
            meta: {
              id: '2-2',
              icon: User,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '用户管理'
            },
            component: () => import('@/views/video-studio/user/index.vue')
          },
          {
            path: '/coupon-code',
            name: "coupon-code",
            meta: {
              id: '2-2',
              icon: User,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '优惠码管理'
            },
            component: () => import('@/views/video-studio/coupon-code/index.vue')
          }
        ]
      },
      {
        path: '/finance',
        name: "finance",
        // redirect: "/withdraw-audit",
        meta: {
          id: '3',
          icon: Coin,
          title: '财务',
          permiss: [1, 2],
          subRole: ['-1'],
        },
        children: [
          {
            path: '/withdraw-audit',
            name: "withdraw-audit",
            meta: {
              id: '3-1',
              icon: CreditCard,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '提现审核'
            },
            component: () => import('@/views/finance/withdraw-audit/index.vue')
          },
          {
            path: '/withdraw-audit-detail',
            name: "withdraw-audit-detail",
            meta: {
              id: '3-1',
              hidden: true,
              icon: CreditCard,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '提现详情'
            },
            component: () => import('@/views/finance/withdraw-audit/components/withdraw-audit-detail.vue')
          },
          {
            path: '/balance-manage',
            name: "balance-manage",
            meta: {
              id: '3-2',
              icon: Wallet,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '余额管理'
            },
            component: () => import('@/views/finance/balance-manage/index.vue'),
          },
          {
            path: '/balance-detail',
            name: "balance-detail",
            meta: {
              id: '3-2',
              icon: Wallet,
              hidden: true,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '余额管理详情'
            },
            component: () => import('@/views/finance/balance-manage/components/balance-detail.vue'),
          },
          {
            path: '/withdraw-setting',
            name: "withdraw-setting",
            meta: {
              id: '3-3',
              icon: Suitcase,
              permiss: [1, 2],
              subRole: ['-1'],
              title: '提现设置'
            },
            component: () => import('@/views/finance/withdraw-setting/index.vue')
          }
        ]
      },
      {
        path: '/common',
        name: "common",
        // redirect: "/public-data",
        meta: {
          id: '4',
          icon: Folder,
          title: '通用业务',
          permiss: [1, 2],
        },
        children: [
          {
            path: '/public-data',
            name: "public-data",
            meta: {
              id: '4-1',
              icon: Document,
              permiss: [1, 2],
              title: '公开资料管理'
            },
            component: () => import('@/views/common/public-data/index.vue')
          }
        ]
      }
    ]
  },
]

export const staticRoutes = [
  {
    path: '/login',
    name: "login",
    meta: {
      name: 'login',
      title: '登录'
    },
    component: () => import("@/views/login/index.vue")
  },
  {
    path: "/:pathMatch(.*)",
    name: "not-found",
    component: () => import("@/views/default.vue")
  }
]
const routeList = [...routes, ...staticRoutes]
const router = createRouter({
  history: createWebHashHistory(),
  routes: routeList,
  // 滚动条回到顶部
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        resolve(savedPosition);
      } else {
        resolve({ x: 0, y: 0 });
      }
    });
  },
})

router.beforeEach(beforeEach)
export default router