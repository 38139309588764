<!--
 * @Descripttion: 系统设置
 * @version: 1.0.0
 * @Author: fulei🐰
 * @Date: 2023-08-17 10:44:35
 * @LastEditors: fulei🐰
 * @LastEditTime: 2024-04-25 16:57:18
-->
<template>
  <el-icon>
    <Setting @click="openDrawer" />
  </el-icon>
  <el-drawer v-model="showDrawer" title="布局设置" :before-close="handleClose" size="300px">
    <div class="setting-content">
      <el-divider>布局设置</el-divider>
      <div class="layout-items flex-between">
        <div class="col-item" ref="layoutColRef" @click="changeLayoutDiretion('col', 'layout')">
          <div class="item-left raduis"></div>
          <div class="item-right">
            <div class="item-bg raduis"></div>
            <div class="item-border raduis"></div>
          </div>
          <el-icon class="checked" v-if="isShowColBtn">
            <SuccessFilled />
          </el-icon>
        </div>
        <div class="col-item second " ref="layoutRowRef" @click="changeLayoutDiretion('row', 'layout')">
          <div class="item-top raduis"></div>
          <div class="item-bottom raduis"></div>
          <el-icon class="checked" v-if="isShowRowBtn">
            <SuccessFilled />
          </el-icon>
        </div>
      </div>
      <el-divider>
        <span>全局主题</span>
      </el-divider>
      <SettingItem label="主题颜色">
        <el-color-picker v-model="theme" @change="((val) => { changeLayout(val, 'theme') })" />
      </SettingItem>
      <SettingItem label="暗黑模式">
        <el-switch v-model="isDark" @change="((val) => { changeLayout(val, 'isDark') })" />
      </SettingItem>
      <SettingItem label="灰色模式">
        <el-switch v-model="isGrey" @change="((val) => { changeLayout(val, 'isGrey') })" />
      </SettingItem>
      <el-divider>
        <span>界面设置</span>
      </el-divider>
      <SettingItem label="菜单折叠">
        <el-switch v-model="isCollapse" @change="((val) => { changeLayout(val, 'isCollapse') })" />
      </SettingItem>
      <SettingItem label="菜单手风琴">
        <el-switch v-model="isAccordion" @change="((val) => { changeLayout(val, 'isAccordion') })" />
      </SettingItem>
      <SettingItem label="面包屑">
        <el-switch v-model="breadcrumb" @change="((val) => { changeLayout(val, 'breadcrumb') })" />
      </SettingItem>
      <SettingItem label="标签栏">
        <el-switch v-model="isNavTabs" @change="((val) => { changeLayout(val, 'isNavTabs') })" />
      </SettingItem>
    </div>
  </el-drawer>
</template>

<script setup>
import { Setting, SuccessFilled } from '@element-plus/icons-vue'
import SettingItem from "./item.vue"
import { useTheme } from '@/hooks/useTheme'
import { ref, watch, nextTick } from 'vue'

const { theme,
  isDark,
  isGrey,
  isCollapse,
  isAccordion,
  breadcrumb,
  isNavTabs,
  changeSettings,
  layout
} = useTheme()
const showDrawer = ref(false)
const openDrawer = () => {
  showDrawer.value = !showDrawer.value
}
const handleClose = () => {
  showDrawer.value = !showDrawer.value
}
const changeLayout = (value, key) => {
  changeSettings({ value, key })
}


const layoutColRef = ref(null)
const layoutRowRef = ref(null)
const isShowColBtn = ref(true)
const isShowRowBtn = ref(false)
watch(showDrawer, async () => {
  if (!showDrawer.value) { return }
  await nextTick()
  if (layout.value === "col") {
    changeLayoutToCol()
  } else {
    changeLayoutToRow()
  }
})
const changeLayoutToCol = () => {
  layoutColRef.value.classList.add("isactive")
  layoutRowRef.value.classList.remove("isactive")
  isShowColBtn.value = true
  isShowRowBtn.value = false
}
const changeLayoutToRow = () => {
  layoutRowRef.value.classList.add("isactive")
  layoutColRef.value.classList.remove("isactive")
  isShowColBtn.value = false
  isShowRowBtn.value = true
}
const changeLayoutDiretion = (value, key) => {
  if (value === "col") {
    changeLayoutToCol()
  } else {
    changeLayoutToRow()
  }
  changeSettings({ value, key })
}

</script>
<style scoped lang='scss'>
.el-icon {
  cursor: pointer;
  margin-right: 20px;
  font-size: 22px;
}

.setting-content {
  margin-top: -30px;
}

.raduis {
  border-radius: 3px;
}

.layout-items {
  >div {
    width: 100px;
    height: 70px;
    border-radius: 10px;
  }

  .col-item.isactive {
    box-shadow: 0 0 0 2px var(--el-color-primary) !important;
  }

  .col-item {
    position: relative;
    padding: 8px;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 0 0 1px #eee;

    &:hover {
      cursor: pointer;
      border: 1px solid var(--el-color-primary-light-3);
    }

    .item-left {
      width: 20%;
      height: 50px;
      background-color: var(--el-color-primary);
    }

    .item-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 70%;

      .item-bg {
        width: 100%;
        height: 25%;
        background-color: var(--el-color-primary-light-3);
      }

      .item-border {
        height: 65%;
        background-color: var(--el-color-primary-light-8);
        border: 1px dashed var(--el-color-primary);

      }
    }

    .item-top {
      height: 30%;
      background-color: var(--el-color-primary);
      width: 100%;
    }

    .item-bottom {
      height: 60%;
      width: 100%;
      background-color: var(--el-color-primary-light-8);
      border: 1px dashed var(--el-color-primary);

    }

    .checked {
      position: absolute;
      bottom: 6px;
      right: -8px;
      width: 14px;
      color: var(--el-color-primary);
    }
  }

  .col-item.second {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
}
</style>